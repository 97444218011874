@font-face {
	font-family: kq;
	src: url(AvQest.ttf);
}

html,
body {
	width: 100%;
	font-family: 'Ariel', sans-serif;
	font-size: 1rem;
	color: #7e7e7e;
	background-color: #1a1a1a;
	margin: 0;
}

#content {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

body {
}

#main {
	padding: 20px;
	background: #111111;
	color: #7e7e7e;
	flex: 1;
	width: 100%;
}
#main h1,
#main h2 {
	color: #fee9d6;
}

#head {
	flex: 1;
	width: 100%;
	background-color: #111111;
	padding: 20px;
	font-family: kq, sans-serif;
}

#head a {
	font-size: 4rem;
	margin-bottom: 10px;
}

#head .site-info {
	font-size: 150%;
	color: #fee9d6;
}

#nav {
	padding: 10px;
	font-family: kq, sans-serif;
	background-color: #2a2a2a;
}

#nav ul {
	margin: 0;
}

#nav li {
	display: inline;
	text-decoration: none;
}

#nav a {
	padding: 10px 30px;
	height: 10px;
}

#nav a:hover {
	background: #3a3a3a;
}

h2 {
	font-family: kq, sans-serif;
}

#foot {
	flex: 1;
	width: 100%;
	padding: 20px;
	background-color: #2a2a2a;
	color: #7e7e7e;
	text-align: right;
	font-style: italic;
}

.side-info {
	background: #1e1e1e;
	border: 1px solid #3a3a3a;
	padding: 5px;
}

.floatright {
	float: right;
}

.edit-button {
	/*display: none;*/
}

.edit-button:hover {
	display: inline;
}

.cat + .cat {
	border-top: 1px solid #2a2a2a;
	margin-top: 25px;
}

.fluff {
	text-align: justify;
}

.table-list {
	width: 100%;
}

.table-list th {
	text-align: left;
}

.rules table {
	border-collapse: collapse;
	margin-top: 10px;
	width: 100%;
}

.rules table td {
	min-width: 100px;
}

.rules table th {
	text-align: left;
}

.rules table tr td,
.rules table tr th {
	padding: 5px;
}

.rules table tr:nth-child(even) {
	background-color: #dadada;
}

.rules table tr:nth-child(even) td {
	border: 1px solid #dadada;
}

.rules table tr:nth-child(odd) {
	border: 1px solid #dadada;
	border-width: 0 1px;
}

.rules table tr:nth-child(1) {
	border: 1px solid #dadada;
	border-width: 1px 1px 0 1px;
}

.rules table tr:last-child {
	border: 1px solid #dadada;
	border-width: 0 1px 1px 1px;
}

.buttons {
	margin: 20px 0;
}

.tabs {
	margin: 30px 0;
}

.tablink {
	padding: 0;
	margin: 0;
	margin-bottom: 2px;
}

.tablink li {
	cursor: pointer;
	display: inline;
	border: 1px solid #3a3a3a;
	border-width: 1px 1px 0 1px;
	margin-left: 0.5em;
	border-radius: 5px 5px 0 0;
	background-color: #1e1e1e;
	padding: 10px 10px 5px 10px;
	color: #dc1535;
}

.tablink .active {
	background-color: #1a1a1a;
	border-bottom: 2px solid #1a1a1a;
	color: #7e7e7e;
	position: relative;
	top: 0;
}

.tablink li a {
	padding: 0 1em;
	cursor: pointer;
	text-decoration: none;
	border-radius: inherit;
}

.tabtext {
	border: 1px solid #3a3a3a;
	padding: 10px;
	background-color: #1a1a1a;
	border-radius: 5px;
}

.third {
	height: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clear {
	clear: both;
	border: none;
}

textarea {
	width: 100%;
	height: 300px;
}

a:hover {
	color: #dc1535;
	text-decoration: none;
}

a {
	color: #dc1535;
	text-decoration: none;
	transition: ease-in-out 0.2s;
}

a:hover {
	text-decoration: none;
	color: #ffffff;
	transition: ease-in-out 0.2s;
}
